import logo from './logo.svg';
import './App.css';
import CommonHomepage from "./Pages/CommonHomepage/CommonHomepage"
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import ApplicationNavigator from './Pages/CommonHomepage/ApplicationNavigator/ApplicationNavigator';


function App() {
  return (
    <div className="App">
       <NotificationContainer />
      {/* <CommonHomepage/> */}
      <ApplicationNavigator />
    </div>
  );
}

export default App;
