import { Link, useNavigate } from "react-router-dom"

const Navbar = () => {
    return (
        <nav className="navbar sticky-top navbar-expand-lg navbar-light ">
            <a to="/dashboard" className="navbar-brand"
                style={{ display: 'flex', alignItems: 'center' }}>
                <div
                    style={{ backgroundColor: 'rgb(255, 255, 255)', padding: '2px', borderRadius: '25%', margin: '2px 10px' }}>
                    <img className="nav-logo" src="img/New/logo-raigad-transparent.png" style={{ margin: '0px' }} />
                </div>
                <div>
                    <h5 className="navbar-heading">जिल्हा परिषद रायगड</h5>
                </div>
            </a>
        </nav>
    )
}
export default Navbar