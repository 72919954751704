import React, { useState } from "react";
import "./application.css";
import Navbar from "../Navbar";
import LoginPage from "../LoginPage";
import $ from 'jquery'
import { GiTakeMyMoney, GiReceiveMoney } from "react-icons/gi";
import { MdOutlineMedicalServices } from "react-icons/md";
import { FaAndroid, FaInfoCircle, FaClinicMedical, FaBuilding } from "react-icons/fa";
import Footer from "../../../components/Footer/Footer";

const ApplicationNavigator = () => {
    const [username, setusername] = useState("")
    const [password, setpassword] = useState("")
    const [application, setapplication] = useState(0)

    const handleScroll = () => {
        // setTimeout(() => {
        //     $('html, body').animate({ scrollTop: 2400 }, '5');
        // }, 150)
    }

    return (

        <div className="App">

            <div className="notification-container notification-container-empty">
                <div></div>
            </div>

            <Navbar />

            <div className="row">
                <div className="col-12" style={{ padding: '0px 10px 0px 0px' }}>
                    <div className="welcome cards-shadow">
                        <img src="img/New/main_banner.jpeg" alt="" className="img-fuild" />
                    </div>
                </div>
            </div>

            <div className="container-row3">
                <div className="row">
                    <div className="col-md-12 col-lg-8" style={{ paddingLeft: '0px', paddingBottom: '10px', paddingRight: '10px' }}>
                        <div className="cards">
                            <div className="card-body">

                                <div className="row m-0">

                                    <div className="col-12 col-md-6 col-lg-6">
                                    {/* style={{backgroundColor:'#2f92bc'}} */}
                                        <a  className={application === 0 ? "card3 card-active active" : "card3 card-active"}
                                            id='bordercard'
                                            onClick={() => {
                                                setapplication(0);
                                                handleScroll()
                                            }}>

                                            <div className="row p-3">
                                                <div id='icon' className="pl-2 gfg">
                                                    <img id='img' src="img/New/SOPI-01.png" />
                                                </div>

                                                <div className="m-auto">
                                                    <p className="card-count3" style={{ color: 'white' }}>System of Pension Illustration</p>
                                                    <hr className="card-count3" style={{ backgroundColor: 'white', width: '60%', margin: 'auto' }}></hr>
                                                    <p className="card-count3" style={{ color: 'white' }}>निवृत्ती वेतन व्यवस्थापन प्रणाली<br/><p style={{ color: 'white', marginTop:'3%' }}>जि.प. व प्राथमिक शिक्षण विभाग</p></p>

                                                </div>
                                            </div>

                                        </a>
                                        <a className="download-link" download rel="noopener noreferrer" href='http://sopi.raigadezp.com/MarathiTypingTool.aspx' >
                                            <FaInfoCircle size={15} color="#FF8B02" /><span className="card-count3" style={{ color: '#311BA1', fontSize: '15px' }}>&nbsp; Help Desk </span>
                                        </a>
                                    </div>





                                    {/* <div className=" col-12 col-md-6 col-lg-4">
                                        <a id="cardLink" className={application === 0 ? "card3 card-active active" : "card3 card-active"}
                                            onClick={() => {
                                                setapplication(0);
                                                handleScroll()
                                            }}>
                                            <div id='icon'>
                                                <GiReceiveMoney size={50} color='white' />
                                            </div>
                                            <div className="m-auto">
                                                <p className="card-count3">System of Pension Illustration</p>
                                                <hr className="card-count3" style={{ backgroundColor: 'white', width: '60%', margin: 'auto' }}></hr>
                                                <p className="card-count3">निवृत्ती वेतन व्यवस्थापन प्रणाली</p>

                                            </div>
                                        </a>
                                        <a className="download-link" download rel="noopener noreferrer" href='http://sopi.raigadezp.com/MarathiTypingTool.aspx' >
                                            <FaInfoCircle size={15} color="#FF8B02" /><span className="card-count3" style={{ color: '#311BA1', fontSize: '15px' }}>&nbsp; Help Desk </span>
                                        </a>
                                    </div> */}


                                    <div className=" col-12 col-md-6 col-lg-6">
                                        <a  className={application === 1 ? "card3 card-active active" : "card3 card-active"}
                                             id='bordercard'
                                            onClick={() => {
                                                setapplication(1);
                                                handleScroll()
                                            }}>
                                            <div className="row p-3">
                                                <div id='icon' className="pl-2 gfg">
                                                    <img id='img' src="img/New/MRMS-01.png" />
                                                </div>

                                                <div className="m-auto">
                                                    <p className="card-count3" style={{ color: 'white' }}>Medical Reimbursement</p>
                                                    <hr className="card-count3" style={{ backgroundColor: 'white', width: '60%', margin: 'auto' }}></hr>
                                                    <p className="card-count3" style={{ color: 'white' }}>वैद्यकीय प्रतिपूर्ती व्यवस्थापन प्रणाली</p>

                                                </div>
                                            </div>
                                        </a>
                                        <a className="download-link" download rel="noopener noreferrer" href='http://sopi.raigadezp.com/MarathiTypingTool.aspx' >
                                            <FaInfoCircle size={15} color="#FF8B02" /><span className="card-count3" style={{ color: '#311BA1', fontSize: '15px' }}>&nbsp; Help Desk </span>
                                        </a>
                                    </div>

                                    {/* <div className=" col-12 col-md-6 col-lg-6">
                                        <a style={{backgroundColor:'#3472F5'}} className={application === 1 ? "card3 card-active active" : "card3 card-active"}
                                            onClick={() => {
                                                setapplication(1);
                                                handleScroll()
                                            }}>
                                            <div className="row p-4">
                                                <div id='icon'>
                                                    <img src="img/ASSET_03.png" style={{ width: '100px'}} />
                                                </div>

                                                <div className="m-auto">
                                                    <p className="card-count3" style={{ color: 'white' }}>Medical Reimbursement</p>
                                                    <hr className="card-count3" style={{ backgroundColor: 'white', width: '60%', margin: 'auto' }}></hr>
                                                    <p className="card-count3" style={{ color: 'white' }}>वैद्यकीय प्रतिपूर्ती व्यवस्थापन प्रणाली</p>

                                                </div>
                                            </div>
                                        </a>
                                        <a className="download-link" download rel="noopener noreferrer" href='http://sopi.raigadezp.com/MarathiTypingTool.aspx' >
                                            <FaInfoCircle size={15} color="#FF8B02" /><span className="card-count3" style={{ color: '#311BA1', fontSize: '15px' }}>&nbsp; Help Desk </span>
                                        </a>
                                    </div> */}


                                    {/* <div className=" col-12 col-md-6 col-lg-4">
                                        <a id="cardLink" className={application === 1 ? "card3 card-active active" : "card3 card-active"}
                                            onClick={() => {
                                                setapplication(1);
                                                handleScroll()
                                            }}>
                                            <div id='icon'>
                                                <FaClinicMedical size={40} color='white' />
                                            </div>

                                            <div className="m-auto">
                                                <p className="card-count3">Medical Reimbursement</p>
                                                <hr className="card-count3" style={{ backgroundColor: 'white', width: '60%', margin: 'auto' }}></hr>
                                                <p className="card-count3">वैद्यकीय प्रतिपूर्ती व्यवस्थापन प्रणाली</p>
                                            </div>
                                        </a>
                                        <a className="download-link" download rel="noopener noreferrer" href='http://sopi.raigadezp.com/MarathiTypingTool.aspx' >
                                            <FaInfoCircle size={15} color="#FF8B02" /><span className="card-count3" style={{ color: '#311BA1', fontSize: '15px' }}>&nbsp; Help Desk </span>
                                        </a>
                                    </div> */}

                                    {/* <div className="col-12 col-md-6 col-lg-4">
                                        <a id="cardLink" className={application === 2 ? "card3 card-active active" : "card3 card-active"}
                                            onClick={() => {
                                                setapplication(2);
                                                handleScroll()
                                            }}>
                                            <div id='icon'>
                                                <FaBuilding size={40} color='white' />
                                            </div>
                                            <div className="m-auto">
                                                <p className="card-count3">Asset Management System</p>
                                                <hr className="card-count3" style={{ backgroundColor: 'white', width: '60%', margin: 'auto' }}></hr>
                                                <p className="card-count3">मालमत्ता व्यवस्थापन प्रणाली</p>
                                            </div>
                                        </a>
                                        <a className="download-link" download rel="noopener noreferrer" href='http://apiasset.raigadezp.com/Upload/APK/ams.apk' >
                                            <FaAndroid size={15} color="#32DE84" />
                                            <span className="card-count3" style={{ color: '#311BA1', fontSize: '15px' }}>&nbsp; Download AMS Mobile App</span>
                                        </a>
                                    </div> */}


                                    {/* <div className="col-12 col-md-6 col-lg-6 mt-2" >
                                        <a className={application === 3 ? "card3 card-active active" : "card3 card-active"}
                                            id='bordercard1'
                                            onClick={() => {
                                                setapplication(3);
                                                handleScroll()
                                            }}>

                                            <div className="row p-3">
                                                <div id='icon' className="pl-2 gfg">
                                                    <img id='img' src="img/New/SECONDARY-01.png" />
                                                </div>

                                                <div className="m-auto">
                                                    <p className="card-count3" style={{ color: 'black' }}>System of Pension Illustration <br/>For Secondary Education</p>
                                                    <hr className="card-count3" style={{ backgroundColor: 'white', width: '60%', margin: 'auto' }}></hr>
                                                    <p className="card-count3" style={{ color: 'black' }}>निवृत्ती वेतन व्यवस्थापन प्रणाली <br/><p style={{ color: 'black', marginTop:'3%' }}>माध्यमिक शिक्षण विभाग</p></p>

                                                </div>
                                            </div>

                                        </a>
                                        <a className="download-link" download rel="noopener noreferrer" href='http://sopi.raigadezp.com/MarathiTypingTool.aspx' >
                                            <FaInfoCircle size={15} color="#FF8B02" /><span className="card-count3" style={{ color: '#311BA1', fontSize: '15px' }}>&nbsp; Help Desk </span>
                                        </a>
                                    </div> */}

                                    <div className=" col-12 col-md-6 col-lg-6 mt-2">
                                    {/* style={{backgroundColor:'#2f92bc'}} */}
                                        <a   className={application === 2 ? "card3 card-active active" : "card3 card-active"}
                                            id='bordercard'
                                            onClick={() => {
                                                setapplication(2);
                                                handleScroll()
                                            }}>
                                            <div className="row p-3">
                                                <div id='icon' className="pl-2 gfg">
                                                    <img id='img' src="img/New/AMS-01.png" />
                                                </div>

                                                <div className="m-auto">
                                                    <p className="card-count3" style={{ color: 'white' }}>Asset Management System</p>
                                                    <hr className="card-count3" style={{ backgroundColor: 'white', width: '60%', margin: 'auto' }}></hr>
                                                    <p className="card-count3" style={{ color: 'white' }}>मालमत्ता व्यवस्थापन प्रणाली</p>

                                                </div>
                                            </div>
                                        </a>
                                        <a className="download-link" download rel="noopener noreferrer" href='http://apiasset.raigadezp.com/Upload/APK/ams.apk' >
                                            <FaAndroid size={15} color="#32DE84" />
                                            <span className="card-count3" style={{ color: '#311BA1', fontSize: '15px' }}>&nbsp; Download AMS Mobile App</span>
                                        </a>
                                    </div>

                                    {/* <div className="card-container3 col-12 col-md-6 col-lg-3" style={{ cursor: 'not-allowed' }}>
                                        <a className="card3 card-active">
                                            <div className="m-auto">
                                                <p className="card-count3">Letter &amp; File Management System (LFMS)</p>
                                            </div>
                                        </a>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>

                    <LoginPage
                        username={username}
                        setusername={setusername}
                        password={password}
                        setpassword={setpassword}
                        application={application}
                        setapplication={setapplication}
                    />

                </div>
            </div>
            <Footer />
        </div>

    )
}

export default ApplicationNavigator;