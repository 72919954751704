import React from 'react'

export default function Footer() {

    const d = new Date();
    var year = d.getFullYear();
    return (
        // style={{position:'absolute', bottom:'0px'}}
        <div style={{ marginBottom: '0%' }}>
            <div className="copyright">
                &#169; Copyright {year}. All Rights Are Reserved.<b> Zilla Parishad Raigad</b>
            </div>
        </div>
    )
}
