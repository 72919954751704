
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";

const LoginPage = ({ username, setusername, password, setpassword, application, setapplication }) => {
    const [loading, setloading] = React.useState(false);
    // console.log('application', application)

    useEffect(() => {
        setusername('')
        setpassword('')
    }, [application])

    const LoginAPI = () => {
        let url
        let ApplicationbaseUrl
        setloading(true)
        if (application === 0) {
            url = `http://sopi.raigadezp.com/login.aspx?UserName=${username}&Pass=${password}`
            // window.open(url)
            ApplicationbaseUrl = 'http://apisopi.raigadezp.com'

        } else if (application === 1) {

            url = `http://mrms.raigadezp.com/Login.aspx?UserName=${username}&Pass=${password}`
            ApplicationbaseUrl = 'http://apimrms.raigadezp.com'

        } else if (application === 2) {
            url = `http://ams.raigadezp.com/#/?username=${username}&password=${password}`
            ApplicationbaseUrl = 'http://apiasset.raigadezp.com'


        } else if (application === 3) {
            let url = `http://sesopi.raigadezp.com/#/dashboard`
            window.open(url)
            // ApplicationbaseUrl = 'http://apiasset.raigadezp.com'

        }
        if (application !== -1) {
            setTimeout(() => {
                fetch(`${ApplicationbaseUrl}/token`, {
                    method: "POST",
                    body: `grant_type=password&Username=${username}&Password=${password}&ApplicationType=WEB`,
                })
                    .then((response) => response.json())
                    .then((result) => {
                        console.log(result)
                        setloading(false)
                        try {
                            if (result.Loginmessage === "User login is successful..") {
                                setusername('')
                                setpassword('')
                                if (application === 1) {
                                    if (result.RoleID === '5' || result.RoleID === '7'|| result.RoleID === '15') {
                                        // window.open(`http://dbmrms.raigadezp.com/#/?username=${username}&password=${password}`)
                                        window.open(`http://mrms.raigadezp.com/Login.aspx?UserName=${username}&Pass=${password}`)

                                    } 
                                    // else if (result.RoleID === '15') {
                                    //     window.open(`http://dbmrms.raigadezp.com/#/?username=${username}&password=${password}`)
                                    // }
                                    else {
                                        window.open(url)
                                    }
                                }

                                if (application === 0) {
                                    // if (result.RoleID === '8' || result.RoleID === '16') {

                                    // } else {
                                    //     window.open(`http://dbsopi.raigadezp.com/#/?username=${username}&password=${password}`)

                                    // }

                                    if (result.RoleID === "4") {
                                        window.open(`http://dbsopi.raigadezp.com//#/?username=${username}&password=${password}`)

                                    } else {
                                        window.open(url)
                                    }
                                }

                                if (application === 2) {
                                    window.open(url)
                                }
                            }
                            else {
                                NotificationManager.error("Invalid Username or Password");
                            }
                        }
                        catch {
                            NotificationManager.error("Invalid Username or Password");
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                        setloading(false)
                        NotificationManager.error("Invalid Username or Password");
                    });
            }, 500);
        } else {
            // window.open(url)
            setloading(false)
        }

    };



    const showPass = () => {
        var x = document.getElementById("myInput");
        var y = document.getElementById("eye");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
        if (y.className === "mdi mdi-eye") {
            y.className = "mdi mdi-eye-off";
        } else {
            y.className = "mdi mdi-eye";
        }
    }


    return (

        <div id="logintop" className="col-md-12 col-lg-4" >
            {/* style={{ padding: '0px 10px' }} */}
            <div className="left_form col-12 col-md-12 col-lg-12" style={{ borderRadius: '8px' }}>
                <div className="limiter">
                    <div className="container-login100">
                        <div className="wrap-login100">
                            <form className="login100-form validate-form mt-3" >
                                <span className="login100-form-title p-b-15"></span>

                                <h4 className="login100-form-title p-b-25" style={{ color: "#fff" }}>
                                    अमृत प्रणाली
                                </h4>

                                <img src="img/amrut.png" style={{ width: '50%' }} />

                                <h6 style={{ color: 'rgb(255, 255, 255)', margin: '0px 0px 10px 0px' }}>
                                    {application === -1 && <h6 style={{ color: "#fff", margin: "8px 0px 5px 0px" }}>Please Select Application Type</h6>}
                                    {application === 0 && <h6 style={{ color: "#fff", margin: "8px 0px 5px 0px" }}>System of Pension Illustration </h6>}
                                    {application === 1 && <h6 style={{ color: "#fff", margin: "8px 0px 5px 0px" }}>Medical Reimbursement Mgmt System</h6>}
                                    {application === 2 && <h6 style={{ color: "#fff", margin: "8px 0px 5px 0px" }}>Asset Management System</h6>}
                                    {application === 3 && <h6 style={{ color: "#fff", margin: "8px 0px 5px 0px" }}>System of Pension Illustration <br />For Secondary Education</h6>}
                                </h6>

                                {application === -1 || application === 4 ? (<></>) : (
                                    <>
                                        <div className="form-group">
                                            <input
                                                disabled={loading || application === -1 || application === 4}
                                                type="text"
                                                name="text"
                                                id="email"
                                                placeholder="Username"
                                                className="form-control"
                                                value={username}
                                                onChange={(e) => setusername(e.target.value)}
                                            />
                                        </div>

                                        {/* <div className="form-group"> */}
                                        <div className="wrap-input100 validate-input input-wrapper">

                                            <span className="btn-show-pass">
                                                <span id='eye' className="mdi mdi-eye"
                                                    onClick={() => showPass()}
                                                ></span>
                                            </span>

                                            <input
                                                disabled={loading || application === -1 || application === 4}
                                                type="password"
                                                name="password"
                                                id="myInput"
                                                placeholder="Password"
                                                className="form-control"
                                                // className="input100 form-cont"

                                                value={password}
                                                onChange={(e) => {
                                                    setpassword(e.target.value);
                                                    document.getElementById('myInput').style.borderColor = '#2c7ac9'

                                                }}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <button
                                                type="button"
                                                // className="login100-form-btn"
                                                id="Submit"
                                                onClick={() => LoginAPI()}
                                                disabled={loading || application === -1 || application === 4}
                                            >
                                                {loading && (
                                                    <i
                                                        className="fa fa-refresh fa-spin"
                                                        style={{ marginRight: "5px" }}
                                                    />
                                                )}
                                                {loading && <span>Login ... </span>}
                                                {!loading && <span>Login</span>}
                                            </button>
                                        </div>
                                    </>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>





    );
}

export default LoginPage
